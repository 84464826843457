import React from 'react';
import {Navbar, Container} from 'react-bootstrap';
import {Link} from "react-router-dom";

import logo from '../../assets/images/logo.svg';
import './Header.scss'
const Header = () => {
    return (
        <Navbar className='topbar' fixed="top" expand="lg">
            <Container fluid>
                <Navbar.Brand href="#home">
                    <img src={logo} />
                </Navbar.Brand>
                {/* <Link className='header-btn' to="/">Buy Now</Link> */}
            </Container>
        </Navbar>
    )
}
export default Header;