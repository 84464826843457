import React from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import {fa} from 'faker/lib/locales';

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
    labels: {
        display: false,
    },
    datasets: [
        {
            label: '9,950,000',
            data: [ 12, 19 ],
            backgroundColor: [
                '#ffc100',
                '#00d285',
            ],
            borderColor: [
                '#fff',
            ],
            borderWidth: 2,
            cutoutPercentage: 40
        },
    ],
};

const Doughnutchart = () => {
    return (
        <div className='pd-15px'>
            <div className='pahse-block'>
                <div>
                    <ul className="phase-status">
                        <li>
                            <div className="phase-status-title">Total</div>
                            <div className="phase-status-subtitle">9,950,000</div>
                        </li>
                        <li>
                            <div className="phase-status-title">Sold</div>
                            <div className="phase-status-subtitle">7,270,577 <span>*</span></div>
                        </li>
                        <li>
                            <div className="phase-status-title">Sale %</div>
                            <div className="phase-status-subtitle">73.1% Sold</div>
                        </li>
                        <li>
                            <div className="phase-status-title">Unsold</div>
                            <div className="phase-status-subtitle">2,679,423</div>
                        </li>
                    </ul>
                    <div className="notes">* Not included pending <strong>2,677,158</strong>  sales.</div>
                </div>
                <div className='chart-phase'>
                    <div className="phase-status-total">
                        <span className="lead">9,950,000</span>
                        <span className="sub">TLE</span>
                    </div>
                    <div className='chart-sale-s2'>
                        <Doughnut className="chart-col" data={data} options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            cutout: 70,
                        }}

                        />
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Doughnutchart