import React from 'react'
import {Col, Container, Row, Card} from 'react-bootstrap';
import Heading from '../../../components/Heading/Heading';
const Issuerdetail = () => {
    return (
        <>
            <div className='page-content'>
                <Container>
                    <Row>

                        <Card>
                            <Card.Body>
                                <Heading heading="Issuer details" text="UD10138" />
                                <h6 className="card-sub-title">Issuer Information</h6>
                                <ul className='data-details-list'>
                                    <li>
                                        <div className='data-details-head'>
                                            Name of proposal
                                        </div>
                                        <div class="data-details-des">Fgggg</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            No. of  Investors
                                        </div>
                                        <div class="data-details-des">3</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Funds raised
                                        </div>
                                        <div class="data-details-des">50 USD</div>
                                    </li>

                                </ul>

                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Issuerdetail