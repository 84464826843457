import React from "react";
import { Col, Form, DropdownButton, Dropdown } from "react-bootstrap";
import logo from "../../assets/images/black-logo.svg";
import Checkbox from "../../components/Checkbox/Checkbox";
import Input from "../../components/Input/Input";
import { Link } from "react-router-dom";
import "boxicons";
import Logform from "../../components/Logform/Logform";
import "./Forgetpassword.scss";
import Custombtn from "../../components/Custombtn/Custombtn";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { login, sendResetLink } from "../../state/actioncreators/auth.actions";

const Forgetpassword = (props) => {
  const dispatch = useDispatch();
  const forgotSchema = Yup.object().shape({
    email: Yup.string().email().required("This Field is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: forgotSchema,

    onSubmit: (values) => {
      _sendResetLink();
    },
  });

  const _changeHandler = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue([name], value);
  };

  const _sendResetLink = () => {
    const data = formik.values;
    console.log('data', data)
    dispatch(sendResetLink(data)).then((res) => {
        console.log('res=====', res)
        props.history.push({
            pathname: '/admin',
        });
        if (res && res.status) {
            // toast.success('Offer created successfully');
        }
    });
  };
  return (
    <>
      <Col className="page-ath-wrap">
        <Col md={8} className="login-bg"></Col>
        <Col md={4} className="right-side">
          <div className="page-wth-header">
            <Link to="/">
              {" "}
              <img className="login-logo" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="page-ath-form">
            <Logform
              heading=""
              subtext="If you forgot your password, well, then we'll email you instructions to reset your password."
            />

            <Form className="login-form" onSubmit={formik.handleSubmit}>
              <Input
                formtext="Your Email Address"
                name="email"
                type="text"
                onChange={(e) => {
                  formik.handleChange(e);
                  _changeHandler(e);
                }}
                value={formik.values.email}
                isInvalid={formik.touched.email && !!formik.errors.email}
                touched={formik.touched.email}
              />
              <span>{formik.errors.email}</span>

              <div className="d-flex check-col align-items-center">
                <Custombtn className="w-100 mt-0" text="Send Reset Link" type="submit" />
                <Link to="/login">Return to login</Link>
              </div>
            </Form>
          </div>

          <div className="page-ath-footer">
            <ul className="socials mb-3">
              <li>
                <a href="#">
                  <box-icon type="logo" name="facebook"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="twitter" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="linkedin" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="github" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="youtube" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="medium" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="telegram" type="logo"></box-icon>
                </a>
              </li>
            </ul>
            <ul className="footerlinks">
              <li>
                <Link to="/privacy-policy">Privacy and Policy</Link>
              </li>
              <li>
                <Link to="/">Terms and Condition </Link>
              </li>
              <div className="mb-0 lang-switch">
                {["up"].map((direction) => (
                  <DropdownButton
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    variant="secondary"
                    title={` EN  `}
                  >
                    <Dropdown.Item eventKey="1">ENGLISH</Dropdown.Item>
                  </DropdownButton>
                ))}
              </div>
            </ul>
            <div className="copyright-text">
              © 2022 Alternative assets. All Right Reserved.
            </div>
          </div>
        </Col>
      </Col>
    </>
  );
};

export default Forgetpassword;
