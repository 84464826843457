import React from 'react';
import {Col} from 'react-bootstrap';
import Input from '../Input/Input';

import './Logform.scss'
const Logform = (props) => {
    return (

        <h2 className='page-ath-heading'>
            {props.heading}
            <small>{props.text}</small>
            <span>{props.subtext}</span>
        </h2>

    );
}

export default Logform;
