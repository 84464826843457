import React from "react";
import {Table, Button, ButtonGroup} from "react-bootstrap";
import Select from "react-select";
import "./Transactiontable.scss";
const Transactiontable = (props) => {
    const options = [
        {value: "1", label: "1"},
        {value: "2", label: "2"},
        {value: "3", label: "3"},
    ];
    return (
        <>
            <Table responsive className="tnx-table all-tranx-table">
                <thead>
                    <tr>

                        <th className="data-col">TRANX ID</th>
                        <th className="data-col">AMOUNT</th>
                        <th className="data-col">BASE AMOUNT </th>
                        <th className="data-col">PAY FROM</th>
                        <th className="data-col">TYPE</th>
                        <th className="data-col">TOKENS</th>
                        <th className="data-col  text-center">Mob. Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="data-item">

                        <td data-th="TRANX ID" className="data-td datePlaced" >
                            <div className="trans-col d-flex align-items-center">
                                <span className="data-state-progres mr-10">
                                    <box-icon name="alarm"></box-icon>
                                </span>
                                <div>
                                    <div className="lead tnx-id">TNX005129</div>
                                    <span className="sub sub-date">06 Feb, 2022 05:20 AM</span>
                                </div>
                            </div>
                        </td>

                        <td data-th="AMOUNT" className="data-td " >
                            <div className="trans-col">
                                <div className="lead tnx-id">25.13</div>
                                <span className="sub sub-symbol">
                                    EUR <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="BASE AMOUNT" className="data-td ">
                            <div className="trans-col">
                                <div className="lead tnx-id">28.75</div>
                                <span className="sub sub-symbol">
                                    USD <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="PAY FROM" className="data-td ">
                            <div className="trans-col">
                                <div className="sub sub-s2 pay-with">Pay via Bank</div>
                                <span className="sub sub-email">
                                    UD09702 <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="TYPE" className="data-td data-type">
                            <Button variant="outline-success" className="">
                                Purchase
                            </Button>
                        </td>
                        <td data-th="TOKENS" className="data-td ">
                            <div className="trans-col">
                                <div className="lead tnx-id">+7000</div>
                                <span className="sub sub-symbol">TLE</span>
                            </div>
                        </td>
                        <td data-th="Mob. Number" className="data-td  text-center">
                            <div className="trans-col">
                                <div className="lead tnx-id">12345478</div>
                            </div>
                        </td>
                    </tr>
                    <tr className="data-item">

                        <td data-th="TRANX ID" className="data-td datePlaced" >
                            <div className="trans-col d-flex align-items-center">
                                <span className="data-state-progres mr-10">
                                    <box-icon name="alarm"></box-icon>
                                </span>
                                <div>
                                    <div className="lead tnx-id">TNX005129</div>
                                    <span className="sub sub-date">06 Feb, 2022 05:20 AM</span>
                                </div>
                            </div>
                        </td>

                        <td data-th="AMOUNT" className="data-td " >
                            <div className="trans-col">
                                <div className="lead tnx-id">25.13</div>
                                <span className="sub sub-symbol">
                                    EUR <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="BASE AMOUNT" className="data-td ">
                            <div className="trans-col">
                                <div className="lead tnx-id">28.75</div>
                                <span className="sub sub-symbol">
                                    USD <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="PAY FROM" className="data-td ">
                            <div className="trans-col">
                                <div className="sub sub-s2 pay-with">Pay via Bank</div>
                                <span className="sub sub-email">
                                    UD09702 <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="TYPE" className="data-td data-type">
                            <Button variant="outline-success" className="">
                                Purchase
                            </Button>
                        </td>
                        <td data-th="TOKENS" className="data-td ">
                            <div className="trans-col">
                                <div className="lead tnx-id">+7000</div>
                                <span className="sub sub-symbol">TLE</span>
                            </div>
                        </td>
                        <td data-th="Mob. Number" className="data-td  text-center">
                            <div className="trans-col">
                                <div className="lead tnx-id">12345478</div>
                            </div>
                        </td>
                    </tr>
                    <tr className="data-item">

                        <td data-th="TRANX ID" className="data-td datePlaced" >
                            <div className="trans-col d-flex align-items-center">
                                <span className="data-state-progres mr-10">
                                    <box-icon name="alarm"></box-icon>
                                </span>
                                <div>
                                    <div className="lead tnx-id">TNX005129</div>
                                    <span className="sub sub-date">06 Feb, 2022 05:20 AM</span>
                                </div>
                            </div>
                        </td>

                        <td data-th="AMOUNT" className="data-td " >
                            <div className="trans-col">
                                <div className="lead tnx-id">25.13</div>
                                <span className="sub sub-symbol">
                                    EUR <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="BASE AMOUNT" className="data-td ">
                            <div className="trans-col">
                                <div className="lead tnx-id">28.75</div>
                                <span className="sub sub-symbol">
                                    USD <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="PAY FROM" className="data-td ">
                            <div className="trans-col">
                                <div className="sub sub-s2 pay-with">Pay via Bank</div>
                                <span className="sub sub-email">
                                    UD09702 <box-icon type="solid" name="info-circle"></box-icon>
                                </span>
                            </div>
                        </td>
                        <td data-th="TYPE" className="data-td data-type">
                            <Button variant="outline-success" className="">
                                Purchase
                            </Button>
                        </td>
                        <td data-th="TOKENS" className="data-td ">
                            <div className="trans-col">
                                <div className="lead tnx-id">+7000</div>
                                <span className="sub sub-symbol">TLE</span>
                            </div>
                        </td>
                        <td data-th="Mob. Number" className="data-td  text-center">
                            <div className="trans-col">
                                <div className="lead tnx-id">12345478</div>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </Table>
            <div className="pagination-bar">
                <div>
                    <ButtonGroup>
                        <Button className="me-2" variant="light">
                            Prev
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button variant="light">Next</Button>
                    </ButtonGroup>
                </div>
                <div className="pagination-info guttar-10px d-flex align-items-center justify-content-sm-end justify-content-mb-end">
                    <div className="pagination-info-text ucap">Page </div>
                    <div className="input-wrap w-80px">
                        {" "}
                        <Select classNameprefix="react-select" defaultOptions placeholder="" options={options} />
                    </div>
                    <div className="pagination-info-text ucap">of 23</div>
                </div>
            </div>
        </>
    );
};

export default Transactiontable;
