import React from 'react'
import {Table, Button, ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";
import Select from "react-select";
import {FaEye, FaBan} from 'react-icons/fa';

const Issuerstable = () => {
    const options = [
        {value: "1", label: "1"},
        {value: "2", label: "2"},
        {value: "3", label: "3"},
    ];
    return (
        <>
            <Table responsive className="tnx-table all-tranx-table">
                <thead>
                    <tr>
                        <th className="data-col">Registration date</th>
                        <th className="data-col">Company name</th>
                        <th className="data-col">Issuer name</th>
                        <th className="data-col">No. of proposals</th>
                        <th className="data-col"> Total amount raised</th>
                        <th className="data-col">Tokens distributed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="data-item">
                        <td data-th="Registration date" className="data-td ">
                            <span className="sub sub-date">06 Feb, 2022</span>
                        </td>
                        <td data-th="Company name" className="data-td ">
                            <div className="lead tnx-id">fggg</div>
                        </td>
                        <td data-th="Issuer name" className="data-td ">
                            <div className="lead tnx-id">Martin Poveda
                            </div>
                        </td>
                        <td data-th="No. of proposals" className="data-td ">
                            <div className="lead tnx-id">3
                            </div>
                        </td>
                        <td data-th="Total amount raised" className="data-td ">
                            <div className="lead tnx-id">0.003
                            </div>
                        </td>
                        <td data-th="Tokens distributed" className="data-td ">
                            <div className="lead tnx-id">11
                            </div>
                        </td>
                        <td className='data-td data-type'>

                            <div className="mb-0 status-col">

                                {[ "start" ].map((direction) => (
                                    <DropdownButton
                                        key={direction}
                                        id={`dropdown-button-drop-${direction}`}
                                        drop={direction}
                                        variant="secondary"
                                    >
                                        <Dropdown.Item eventKey="1" href="/admin/issuerdetail"><span className='me-2' ><FaEye /></span> View details </Dropdown.Item>

                                    </DropdownButton>
                                ))}
                            </div>
                        </td>
                    </tr>
                    <tr className="data-item">
                        <td data-th="Registration date" className="data-td ">
                            <span className="sub sub-date">06 Feb, 2022</span>
                        </td>
                        <td data-th="Company name" className="data-td ">
                            <div className="lead tnx-id">fggg</div>
                        </td>
                        <td data-th="Issuer name" className="data-td ">
                            <div className="lead tnx-id">Martin Poveda
                            </div>
                        </td>
                        <td data-th="No. of proposals" className="data-td ">
                            <div className="lead tnx-id">3
                            </div>
                        </td>
                        <td data-th="Total amount raised" className="data-td ">
                            <div className="lead tnx-id">0.003
                            </div>
                        </td>
                        <td data-th="Tokens distributed" className="data-td ">
                            <div className="lead tnx-id">11
                            </div>
                        </td>
                        <td className='data-td data-type'>

                            <div className="mb-0 status-col">

                                {[ "start" ].map((direction) => (
                                    <DropdownButton
                                        key={direction}
                                        id={`dropdown-button-drop-${direction}`}
                                        drop={direction}
                                        variant="secondary"
                                    >
                                        <Dropdown.Item eventKey="1" href="/admin/issuerdetail"><span className='me-2' ><FaEye /></span> View details </Dropdown.Item>

                                    </DropdownButton>
                                ))}
                            </div>
                        </td>
                    </tr>
                    <tr className="data-item">
                        <td data-th="Registration date" className="data-td ">
                            <span className="sub sub-date">06 Feb, 2022</span>
                        </td>
                        <td data-th="Company name" className="data-td ">
                            <div className="lead tnx-id">fggg</div>
                        </td>
                        <td data-th="Issuer name" className="data-td ">
                            <div className="lead tnx-id">Martin Poveda
                            </div>
                        </td>
                        <td data-th="No. of proposals" className="data-td ">
                            <div className="lead tnx-id">3
                            </div>
                        </td>
                        <td data-th="Total amount raised" className="data-td ">
                            <div className="lead tnx-id">0.003
                            </div>
                        </td>
                        <td data-th="Tokens distributed" className="data-td ">
                            <div className="lead tnx-id">11
                            </div>
                        </td>
                        <td className='data-td data-type'>

                            <div className="mb-0 status-col">

                                {[ "start" ].map((direction) => (
                                    <DropdownButton
                                        key={direction}
                                        id={`dropdown-button-drop-${direction}`}
                                        drop={direction}
                                        variant="secondary"
                                    >
                                        <Dropdown.Item eventKey="1" href="/admin/issuerdetail"><span className='me-2' ><FaEye /></span> View details </Dropdown.Item>

                                    </DropdownButton>
                                ))}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="pagination-bar">
                <div>
                    <ButtonGroup>
                        <Button className="me-2" variant="light">
                            Prev
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button variant="light">Next</Button>
                    </ButtonGroup>
                </div>
                <div className="pagination-info guttar-10px d-flex align-items-center justify-content-sm-end justify-content-mb-end">
                    <div className="pagination-info-text ucap">Page </div>
                    <div className="input-wrap w-80px">
                        {" "}
                        <Select defaultOptions placeholder="" options={options} />
                    </div>
                    <div className="pagination-info-text ucap">of 23</div>
                </div>
            </div>
        </>
    )
}

export default Issuerstable