import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            // text: 'Chart.js Line Chart',
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            }

        },
        y: {
            ticks: {
                maxTicksLimit: 11
            }
        }

    }
};

const labels = [ '27 Jan', '28 Jan', '29 Jan', '30 Jan', '31 Jan', '01 Feb', '02 Feb', '03 Feb', '04 Feb', '05 Feb', '06 Feb', '07 Feb', '08 Feb', '08 Feb', '10 Feb' ];
export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({min: 0, max: 70000})),
            borderColor: ' #7668fe',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },

    ],
};

const Linechart = () => {
    return (

        <div className='line-sale-graph'>
            <Line options={options} data={data}

            />
        </div>
    )
};

export default Linechart;
