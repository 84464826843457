import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Forgetpassword from "../../pages/Forgetpassword/Forgetpassword";
import Home from "../../pages/Home/Home";
import Login from "../../pages/Login/Login";
import Privacypolicy from "../../pages/Privacypolicy/Privacypolicy";
import Reset from "../../pages/Resetpassword/Resetpassword";
import Signup from "../../pages/Signup/Signup";
const PublicRoutes = () => {
  return (
    <>
      <Route path={"/login"} component={Login} exact={true} />
      <Route path={"/register"} component={Signup} exact={true} />
      <Route path={"/reset"} component={Reset} exact={true} />
      <Route path={"/forgot"} component={Forgetpassword} exact={true} />
      <Route path={"/privacy-policy"} component={Privacypolicy} exact={true} />
      <Route path={"/"} component={Home} exact={true} />
    </>
  );
};
export default PublicRoutes;
