import { REMEMBER_ME } from "../constant/actionTypes";

const initialState = {
  isRemember: false,
};

export default function remember(state = initialState, action = {}) {
  switch (action.type) {
    case REMEMBER_ME:
      return {
        ...state,
        isRemember: action.payload?.isRemember ?? false,
        data: action.payload,
      };
    default:
      return state;
  }
}
