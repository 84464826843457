import React from 'react'
import {Table, Button, ButtonGroup, Dropdown, DropdownButton} from 'react-bootstrap'
import {Link} from "react-router-dom";

import {BsExclamationTriangle, BsQuestionCircle, BsShieldShaded} from 'react-icons/bs';
import {FaEye, FaBan} from 'react-icons/fa';
import Select from 'react-select'
import './Userlist.scss'
const Usertable = () => {
    const options = [
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'}
    ]
    return (
        <>
            <Table responsive className='tnx-table all-tranx-table user-table'>
                <thead>
                    <tr>
                        <th className='data-col'>USER</th>
                        <th className='data-col'>EMAIL</th>
                        <th className='data-col'>Invested proposals	</th>
                        <th className='data-col'>VERIFIED STATUS</th>
                        <th className='data-col'>LAST LOGIN	</th>
                        <th className='data-col'>STATUS</th>
                        <th className='data-col'></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='data-item'>
                        <td data-th="USER" className='data-td '>

                            <div className='trans-col d-flex align-items-center'>
                                <div>
                                    <div className='lead tnx-id'>dnzd</div>
                                    <span className='sub sub-date'>
                                        UD10072
                                    </span>
                                </div>
                            </div>

                        </td>

                        <td data-th="EMAIL" className='data-td '>
                            <div className='trans-col'>
                                <div className='sub sub-s2 pay-with'>con...com
                                </div>

                            </div>
                        </td>
                        <td data-th="Invested proposals	" className='data-td '>
                            <div className='trans-col'>
                                <div className='lead tnx-id'>0
                                </div>

                            </div>
                        </td>
                        <td data-th="VERIFIED STATUS" className='data-td '>
                            <div className='trans-col '>

                                <div className='sub sub-s2 pay-with verified-check'>
                                    <box-icon name='check-circle'></box-icon>
                                    Mobile number
                                    <span className='kyc-icon'><BsQuestionCircle /> </span>
                                    KYC
                                </div>

                            </div>
                        </td>
                        <td data-th="LAST LOGIN" className='data-td '>
                            <div className='trans-col'>
                                <div className='sub sub-s2 pay-with'>11 Feb, 2022 04:08 PM

                                </div>

                            </div>
                        </td>
                        <td data-th="STATUS" className='data-td data-type'>
                            <Button variant="outline-success" className=''>
                                Active

                            </Button>
                        </td>
                        <td data-th="Tokens distributed" className='data-td data-type'>

                            <div className="mb-0 status-col">

                                {[ "start" ].map((direction) => (
                                    <DropdownButton
                                        key={direction}
                                        id={`dropdown-button-drop-${direction}`}
                                        drop={direction}
                                        variant="secondary"
                                    // title={` EN  `}
                                    >
                                        <Dropdown.Item eventKey="1" href="/admin/details"><span className='me-2' ><FaEye /></span> View details </Dropdown.Item>
                                        <Dropdown.Item eventKey="2" ><span className='me-2'><BsShieldShaded /> </span>Reset Password</Dropdown.Item>
                                        <Dropdown.Item eventKey="3"> <span className='me-2'><FaBan /> </span>suspend account</Dropdown.Item>
                                    </DropdownButton>
                                ))}
                            </div>
                        </td>

                    </tr>
                    <tr className='data-item'>
                        <td data-th="USER" className='data-td '>

                            <div className='trans-col d-flex align-items-center'>
                                <div>
                                    <div className='lead tnx-id'>dnzd</div>
                                    <span className='sub sub-date'>
                                        UD10072
                                    </span>
                                </div>
                            </div>

                        </td>

                        <td data-th="EMAIL" className='data-td '>
                            <div className='trans-col'>
                                <div className='sub sub-s2 pay-with'>con...com
                                </div>

                            </div>
                        </td>
                        <td data-th="Invested proposals	" className='data-td '>
                            <div className='trans-col'>
                                <div className='lead tnx-id'>0
                                </div>

                            </div>
                        </td>
                        <td data-th="VERIFIED STATUS" className='data-td '>
                            <div className='trans-col '>

                                <div className='sub sub-s2 pay-with verified-check'>
                                    <box-icon name='check-circle'></box-icon>
                                    Mobile number
                                    <span className='kyc-icon'><BsQuestionCircle /> </span>
                                    KYC
                                </div>

                            </div>
                        </td>
                        <td data-th="LAST LOGIN" className='data-td '>
                            <div className='trans-col'>
                                <div className='sub sub-s2 pay-with'>11 Feb, 2022 04:08 PM

                                </div>

                            </div>
                        </td>
                        <td data-th="STATUS" className='data-td data-type'>
                            <Button variant="outline-success" className=''>
                                Active

                            </Button>
                        </td>
                        <td data-th="Tokens distributed" className='data-td data-type'>

                            <div className="mb-0 status-col">

                                {[ "start" ].map((direction) => (
                                    <DropdownButton
                                        key={direction}
                                        id={`dropdown-button-drop-${direction}`}
                                        drop={direction}
                                        variant="secondary"
                                    // title={` EN  `}
                                    >
                                        <Dropdown.Item eventKey="1" href="/admin/details"><span className='me-2' ><FaEye /></span> View details </Dropdown.Item>
                                        <Dropdown.Item eventKey="2" ><span className='me-2'><BsShieldShaded /> </span>Reset Password</Dropdown.Item>
                                        <Dropdown.Item eventKey="3"> <span className='me-2'><FaBan /> </span>suspend account</Dropdown.Item>
                                    </DropdownButton>
                                ))}
                            </div>
                        </td>

                    </tr>
                    <tr className='data-item'>
                        <td data-th="USER" className='data-td '>

                            <div className='trans-col d-flex align-items-center'>
                                <div>
                                    <div className='lead tnx-id'>dnzd</div>
                                    <span className='sub sub-date'>
                                        UD10072
                                    </span>
                                </div>
                            </div>

                        </td>

                        <td data-th="EMAIL" className='data-td '>
                            <div className='trans-col'>
                                <div className='sub sub-s2 pay-with'>con...com
                                </div>

                            </div>
                        </td>
                        <td data-th="Invested proposals	" className='data-td '>
                            <div className='trans-col'>
                                <div className='lead tnx-id'>0
                                </div>

                            </div>
                        </td>
                        <td data-th="VERIFIED STATUS" className='data-td '>
                            <div className='trans-col '>

                                <div className='sub sub-s2 pay-with verified-check'>
                                    <box-icon name='check-circle'></box-icon>
                                    Mobile number
                                    <span className='kyc-icon'><BsQuestionCircle /> </span>
                                    KYC
                                </div>

                            </div>
                        </td>
                        <td data-th="LAST LOGIN" className='data-td '>
                            <div className='trans-col'>
                                <div className='sub sub-s2 pay-with'>11 Feb, 2022 04:08 PM

                                </div>

                            </div>
                        </td>
                        <td data-th="STATUS" className='data-td data-type'>
                            <Button variant="outline-success" className=''>
                                Active

                            </Button>
                        </td>
                        <td data-th="Tokens distributed" className='data-td data-type'>

                            <div className="mb-0 status-col">

                                {[ "start" ].map((direction) => (
                                    <DropdownButton
                                        key={direction}
                                        id={`dropdown-button-drop-${direction}`}
                                        drop={direction}
                                        variant="secondary"
                                    // title={` EN  `}
                                    >
                                        <Dropdown.Item eventKey="1" href="/admin/details"><span className='me-2' ><FaEye /></span> View details </Dropdown.Item>
                                        <Dropdown.Item eventKey="2" ><span className='me-2'><BsShieldShaded /> </span>Reset Password</Dropdown.Item>
                                        <Dropdown.Item eventKey="3"> <span className='me-2'><FaBan /> </span>suspend account</Dropdown.Item>
                                    </DropdownButton>
                                ))}
                            </div>
                        </td>

                    </tr>
                </tbody>
            </Table>
            <div className='pagination-bar'>
                <div>
                    <ButtonGroup>
                        <Button className="me-2" variant="light">Prev</Button>

                    </ButtonGroup>
                    <ButtonGroup>
                        <Button variant="light">Next</Button>
                    </ButtonGroup>
                </div>
                <div className="pagination-info guttar-10px d-flex align-items-center justify-content-sm-end justify-content-mb-end">
                    <div className="pagination-info-text ucap">Page </div>
                    <div className="input-wrap w-80px"> <Select defaultOptions placeholder="" options={options} /></div>
                    <div className="pagination-info-text ucap">of 23</div>
                </div>
            </div>

        </>
    )
}

export default Usertable