import { APIURL } from "../../constant";
import { apiCallPost } from "../../services/axios";
import { REMEMBER_ME, USER } from "../constant/actionTypes";

export const login = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.LOGIN, data, {}, true, true)
        .then((result) => {
          if (result) {
            dispatch({ type: USER, payload: result?.data });
            if (data.remember_me) {
              delete data.remember_me;
              dispatch({
                type: REMEMBER_ME,
                payload: { ...data, isRemember: true },
              });
            } else {
              dispatch({ type: REMEMBER_ME });
            }
            resolve(result);
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    });
};

export const signUp = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.SIGNUP, data, {}, true, true)
        .then((result) => {
          if (result) {
            console.log("result======", result);
            dispatch({ type: USER, payload: result?.data });
            resolve(result);
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    });
};

export const sendResetLink = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.SIGNUP, data, {}, true, true)
        .then((result) => {
          if (result) {
            console.log("result======", result);
            dispatch({ type: USER, payload: result?.data });
            resolve(result);
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    });
};

export const resetPassword = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.SIGNUP, data, {}, true, true)
        .then((result) => {
          if (result) {
            console.log("result======", result);
            dispatch({ type: USER, payload: result?.data });
            resolve(result);
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    });
};
