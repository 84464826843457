import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoutes/PrivateRoute";
import PublicRoutes from "./routes/PublicRoutes/PublicRoutes";
import AuthGuard from "./routes/AuthGuard"
import LoaderComponent from "./components/LoaderCompoent/LoaderCompoent";
const Application = () => {
  return (
    <Router>
      <LoaderComponent />
      <Switch>
        <Route path={"/admin"} component={PrivateRoute} />
        <Route path={"/"} component={PublicRoutes} />
      </Switch>
    </Router>
  );
};
export default Application;
