export const rootName = "";
export const API_HOST = "http://10.1.4.247:8626/api/v1";

export const APIURL = {
  LOGIN: `/admin/auth/login`,
  SIGNUP: `/admin/auth/signup`,
  SIGNUP: `/admin/auth/signup`,


};
export const RESPONSES ={
  CREATED : 201,
  SUCCESS : 200,
  ACCEPTED : 202,
  NOCONTENT : 204,
  BADREQUEST : 400,
  FORBIDDEN : 403,
  NOTFOUND : 404,
  TIMEOUT : 408,
  CONFLICT : 409,
  LENGTHREQUIRED : 411,
  UNRESPONSIBLEENTITY : 422,
  TOOMANYREQ : 429,
  INTERNALSERVER : 500,
  BADGATEWAYS : 502,
  SERVICEUNAVILABLE : 503,
  GATEWAYTIMEOUT : 504,
}
