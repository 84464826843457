import React from "react";
import {Route} from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HeaderWrap from "../../components/HeaderWrap/HeaderWrap";

import Dashboard from "../../pages/AdminPanel/Dashboard/Dashboard";
import Icostage from "../../pages/AdminPanel/Icostage/Icostage";
import Issuerdetail from "../../pages/AdminPanel/Issuerslist/Issuerdetail";
import Issuerslist from "../../pages/AdminPanel/Issuerslist/Issuerslist";
import Transactions from "../../pages/AdminPanel/Transactions/Transactions";
import Userdetail from "../../pages/AdminPanel/Userdetail/Userdetail";
import Userlist from "../../pages/AdminPanel/Userlist/Userlist";

const PrivateRoute = () => {
  return (
    <>
      <HeaderWrap />
      <Route path={"/admin"} component={Dashboard} exact={true} />
      <Route
        path={"/admin/transactions"}
        component={Transactions}
        exact={true}
      />
      <Route path={"/admin/user"} component={Userlist} exact={true} />
      <Route path={"/admin/stages"} component={Icostage} exact={true} />

      <Route path={"/admin/details"} component={Userdetail} exact={true} />
      <Route path={"/admin/list"} component={Issuerslist} exact={true} />
      <Route path={"/admin/issuerdetail"} component={Issuerdetail} exact={true} />

      <Footer />
    </>
  );
};
export default PrivateRoute;
