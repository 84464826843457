import React from "react";
import {Col, Container, Row, Card, Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import Amounttab from "./Amounttab";
import Barchart from "./Chart/Barchart";
import Doughnutchart from "./Chart/Doughnutchart";
import Linechart from "./Chart/Linechart";
import "./Dashboard.scss";
import Totaltab from "./Totaltab";
const Dashboard = () => {
  return (
    <>

      <div className="page-content">
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <Card>
                <Card.Body>
                  <div className="tile-header">
                    <h6 className="tile-title"> Sale - Demo Stage 4</h6>
                  </div>
                  <div className="tile-data">
                    <span className="tile-data-number">9,950,000</span>
                    <span className="tile-data-status tile-data-active">73.1%</span>
                  </div>
                  <div className="tile-footer">
                    <div className="tile-recent">
                      <span className="tile-recent-number">0</span>
                      <span className="tile-recent-text">since last week</span>
                    </div>
                    <div className="tile-link">
                      <a href="#" className="link link-thin link-ucap link-dim">
                        View
                      </a>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6}>
              <Card>
                <Card.Body>
                  <Totaltab />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={12}>
              <Card className="darkCard ">
                <Card.Body>
                  <div className="tile-header balance-text">
                    <h6 className="tile-title">AMOUNT COLLECTED</h6>
                    <span className="lead">
                      5,452,722.626
                      <span>USD</span>
                    </span>
                  </div>
                  <Amounttab />
                </Card.Body>
              </Card>
            </Col>

            <Col lg={5}>
              <Card className="trasaction-col">
                <Card.Body>
                  <div className="card-head">
                    <h4 className="card-title card-title-sm">
                      Recent Transaction
                    </h4>
                    <div className="card-opt">
                      <a href="#" className="link ucap">
                        View ALL <box-icon name="chevron-right"></box-icon>
                      </a>
                    </div>
                  </div>
                  <Table className="tnx-table">
                    <tbody>
                      <tr>
                        <td>
                          <h5 className="lead mb-1">TNX005129</h5>
                          <span className="sub">06 Feb, 2022 05:20 AM</span>
                        </td>
                        <td className="d-none d-sm-table-cell">
                          <h5 className="lead mb-1">+7000</h5>
                          <span className="sub"> 25.13 eur</span>
                        </td>
                        <td className="text-right ">
                          <span className="data-state-progres">
                            <box-icon name="alarm"></box-icon>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h5 className="lead mb-1">TNX005129</h5>
                          <span className="sub">06 Feb, 2022 05:20 AM</span>
                        </td>
                        <td className="d-none d-sm-table-cell">
                          <h5 className="lead mb-1">+7000</h5>
                          <span className="sub"> 25.13 eur</span>
                        </td>
                        <td className="text-right ">
                          <span className="data-state-progres">
                            <box-icon name="alarm"></box-icon>
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <h5 className="lead mb-1">TNX005129</h5>
                          <span className="sub">06 Feb, 2022 05:20 AM</span>
                        </td>
                        <td className="d-none d-sm-table-cell">
                          <h5 className="lead mb-1">+7000</h5>
                          <span className="sub"> 25.13 eur</span>
                        </td>
                        <td className="text-right ">
                          <span className="data-state-progres cancel">
                            <box-icon name="x"></box-icon>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h5 className="lead mb-1">TNX005129</h5>
                          <span className="sub">06 Feb, 2022 05:20 AM</span>
                        </td>
                        <td className="d-none d-sm-table-cell">
                          <h5 className="lead mb-1">+7000</h5>
                          <span className="sub"> 25.13 eur</span>
                        </td>
                        <td className="text-right ">
                          <span className="data-state-progres cancel">
                            <box-icon name="x"></box-icon>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={7}>
              <Card>
                <Card.Body>
                  <div className="card-head">
                    <h4 className="card-title card-title-sm"> Sale Graph</h4>
                    <div className="card-opt">
                      <a href="#" className="link ucap">
                        View ALL <box-icon name="chevron-right"></box-icon>
                      </a>
                    </div>
                  </div>
                  <Linechart />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={5}>
              <Card>
                <Card.Body>
                  <div className="card-head">
                    <h4 className="card-title card-title-sm">
                      Registration Statistics
                    </h4>
                    <div className="card-opt">
                      <a href="#" className="link ucap">
                        View ALL <box-icon name="chevron-right"></box-icon>
                      </a>
                    </div>
                  </div>
                  <Barchart />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={7}>
              <Card>
                <Card.Body>
                  <div className="card-head">
                    <h4 className="card-title card-title-sm">Stage</h4>
                    <div className="card-opt">
                      <a href="#" className="link ucap">
                        View ALL <box-icon name="chevron-right"></box-icon>
                      </a>
                    </div>
                  </div>
                  <Doughnutchart />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Dashboard;
