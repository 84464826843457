import React from 'react';
import {Tabs, Tab} from 'react-bootstrap'

const Amounttab = () => {
    return (
        <>
            <Tabs defaultActiveKey="fiat" id="uncontrolled-tab-example" className="mb-3 tile-nav">
                <Tab eventKey="fiat" title="proposal1 ">
                    <ul className='balance-list'>
                        <li>
                            <span className="lead">970844.588</span>
                            <span className="sub">USD</span>
                        </li>
                        <li>
                            <span className="lead">970844.588</span>
                            <span className="sub">EUR</span>
                        </li>
                        <li>
                            <span className="lead">970844.588</span>
                            <span className="sub">GBP</span>
                        </li>
                    </ul>
                </Tab>
                <Tab eventKey="crypto" title="proposal2">

                    <ul className='balance-list'>
                        <li>
                            <span className="lead">5221.841</span>
                            <span className="sub">ETH</span>
                        </li>
                        <li>
                            <span className="lead">43.75
                            </span>
                            <span className="sub">BTC</span>
                        </li>
                        <li>
                            <span className="lead">46.1
                            </span>
                            <span className="sub">LTC</span>
                        </li>
                    </ul>
                </Tab>

            </Tabs>
        </>

    );
}

export default Amounttab;
