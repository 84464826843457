import React from "react";
import { BsChevronRight } from "react-icons/bs";

import "./Heading.scss";
const Heading = (props) => {
  return (
    <>
      <div className="card-head has-aside">
        <h4 className="card-title">
          {props.heading}
          {props.text ? (
            <small className="tnx-id">
              {" "}
              <BsChevronRight /> {props.text}
            </small>
          ) : null}
        </h4>
      </div>
    </>
  );
};

export default Heading;
