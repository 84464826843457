import React from 'react';
import {Tabs, Tab} from 'react-bootstrap'
import './Dashboard.scss'
const Totaltab = () => {
    return (
        <>
            <Tabs defaultActiveKey="view-kycs" id="uncontrolled-tab-example" className="mb-3 tile-nav">
                <Tab eventKey="view-kycs" title="KYC">
                    <div className="tile-header">
                        <h6 className="tile-title">TOTAL KYC</h6>
                    </div>
                    <div className="tile-data">
                        <span className="tile-data-number">120</span>
                        <span className="tile-data-status tile-data-active">11%</span>
                    </div>
                    <div className="tile-footer">
                        <div className="tile-recent">
                            <span className="tile-recent-number">2</span>
                            <span className="tile-recent-text">since last week</span>
                        </div>
                        <div className="tile-link">
                            <a href="#" className="link link-thin link-ucap link-dim">View</a>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="user" title="User">
                    <div className="tile-header">
                        <h6 className="tile-title">TOTAL USERS</h6>
                    </div>
                    <div className="tile-data">
                        <span className="tile-data-number">9,911</span>
                        <span className="tile-data-status tile-data-active">75%</span>
                    </div>
                    <div className="tile-footer">
                        <div className="tile-recent">
                            <span className="tile-recent-number">97</span>
                            <span className="tile-recent-text">since last week</span>
                        </div>
                        <div className="tile-link">
                            <a href="#" className="link link-thin link-ucap link-dim">View</a>
                        </div>
                    </div>
                </Tab>

            </Tabs>
        </>
    )
};

export default Totaltab;
