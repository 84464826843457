import React from 'react';
import Subheader from '../Subheader/Subheader';
import Topbar from '../Topbar/Topbar';

const HeaderWrap = () => {
    return (
        <div className='header-wrap'>
            <Topbar />
            <Subheader />
        </div>
    )
}

export default HeaderWrap;
