import React from "react";
import { Col, Form, DropdownButton, Dropdown } from "react-bootstrap";
import logo from "../../assets/images/black-logo.svg";
import Checkbox from "../../components/Checkbox/Checkbox";
import Input from "../../components/Input/Input";
import { Link } from "react-router-dom";
import "boxicons";
import Logform from "../../components/Logform/Logform";
import "./login.scss";
import Custombtn from "../../components/Custombtn/Custombtn";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../state/actioncreators/auth.actions";

const Login = (props) => {
  const dispatch = useDispatch();
  const rememberData = useSelector((state) => state?.remember);
  console.log("rememberData", rememberData);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required("This Field is required"),
    password: Yup.string().required("This Field is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: rememberData?.data?.email ?? "",
      password: rememberData?.data?.password ?? "",
      remember_me: rememberData?.isRemember ?? false,
    },
    validationSchema: loginSchema,

    onSubmit: (values) => {
      _Login();
    },
  });

  const _changeHandler = (e) => {
    const { name, value, checked } = e.target;
    if (name === "remember_me") formik.setFieldValue([name], checked);
    else formik.setFieldValue([name], value);
  };
  const _Login = () => {
    const data = formik.values;
    dispatch(login(data)).then((res) => {
      props.history.push({
        pathname: "/admin/",
      });
    });
  };
  return (
    <>
      <Col className="page-ath-wrap">
        <Col md={8} className="login-bg"></Col>
        <Col md={4} className="right-side">
          <div className="page-wth-header">
            <Link to="/">
              {" "}
              <img className="login-logo" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="page-ath-form">
            <Logform
              heading="Sign in"
              text="with your Alternative assets Account"
            />
            {/* onSubmit={formik.handleSubmit} */}
            <Form className="login-form" >
              <Input
                formtext="Email"
                name="email"
                type="text"
                onChange={(e) => {
                  formik.handleChange(e);
                  _changeHandler(e);
                }}
                value={formik.values.email}
                isInvalid={formik.touched.email && !!formik.errors.email}
                // touched={formik.touched.email}
                error={formik.errors.email}
              />
              <Input
                formtext="Password"
                type="password"
                name="password"
                onChange={(e) => {
                  formik.handleChange(e);
                  _changeHandler(e);
                }}
                value={formik.values.password}
                touched={formik.touched.password}
                isInvalid={formik.touched.password && !!formik.errors.password}
                error={formik.errors.password}
              />
              <div className="d-flex check-col">
                <div className="d-flex align-items-center">
                  <Checkbox
                    name="remember_me"
                    checked={formik.values.remember_me}
                    onChange={(e) => {
                      formik.handleChange(e);
                      _changeHandler(e);
                    }}
                  />
                  <label>Remember Me</label>
                </div>
                <Link to="/forgot">Forgot password?</Link>
              </div>
              <Link to="/admin">
              <Custombtn
                className="w-100"
                text="Sign In"
                type="submit"
                disabled={Object.keys(formik.errors).length !== 0}
              />
              </Link>
            </Form>
            <div className="form-note">
              Don’t have an account?{" "}
              <Link to="/register">
                {" "}
                <strong>Sign up here</strong>
              </Link>
            </div>
          </div>

          <div className="page-ath-footer">
            <ul className="socials mb-3">
              <li>
                <a href="#">
                  <box-icon type="logo" name="facebook"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="twitter" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="linkedin" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="github" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="youtube" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="medium" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="telegram" type="logo"></box-icon>
                </a>
              </li>
            </ul>
            <ul className="footerlinks">
              <li>
                <Link to="/privacy-policy">Privacy and Policy</Link>
              </li>
              <li>
                <Link to="/">Terms and Condition </Link>
              </li>
              <li className="mb-0 lang-switch">
                {["up"].map((direction) => (
                  <DropdownButton
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    variant="secondary"
                    title={` EN  `}
                  >
                    <Dropdown.Item eventKey="1">ENGLISH</Dropdown.Item>
                  </DropdownButton>
                ))}
              </li>
            </ul>
            <div className="copyright-text">
              © 2022 Alternative assets. All Right Reserved.
            </div>
          </div>
        </Col>
      </Col>
    </>
  );
};

export default Login;
