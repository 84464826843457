import React from 'react'
import {Col, Container, Row, Card, Badge, ProgressBar} from 'react-bootstrap';
import Heading from '../../../components/Heading/Heading';
import './Userdetail.scss'
const Userdetail = () => {
    return (
        <>
            <div className='page-content'>
                <Container>
                    <Row>

                        <Card>
                            <Card.Body>
                                <Heading heading="User Details" text="UD10138" />
                                <h6 className="card-sub-title">User Information</h6>
                                <ul className='data-details-list'>
                                    <li>
                                        <div className='data-details-head'>
                                            Name
                                        </div>
                                        <div className="data-details-des">Fgggg</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Number
                                        </div>
                                        <div className="data-details-des">12345678</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Wallet address
                                        </div>
                                        <div className="data-details-des">Fgggg</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Date of joining
                                        </div>
                                        <div className="data-details-des">06 Apr, 2019</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Last login
                                        </div>
                                        <div className="data-details-des">15 Feb, 2022 03:27 AM
                                        </div>
                                    </li>
                                </ul>
                                <div className="gaps-3x"></div>
                                <h6 className="card-sub-title">Investment Portfolio</h6>
                                <ul className='data-details-list'>
                                    <li>
                                        <div className='data-details-head'>
                                            Date of investment
                                        </div>
                                        <div className="data-details-des">06 Apr, 2019</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Proposal name
                                        </div>
                                        <div className="data-details-des">12345678</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Amount invested
                                        </div>
                                        <div className="data-details-des">Fgggg</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Tokens earned
                                        </div>
                                        <div className="data-details-des">1111</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Last login
                                        </div>
                                        <div className="data-details-des">01:41 PM</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Poll
                                        </div>
                                        <div className="data-details-des">fgggg</div>
                                    </li>
                                    <li>
                                        <div className='data-details-head'>
                                            Status
                                        </div>
                                        <div className="data-details-des">Upvote/Downvote</div>
                                    </li>
                                </ul>

                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Userdetail