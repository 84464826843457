import React from "react";
import {FaSearch, FaFileExport} from "react-icons/fa";

import {Form, Tabs, Tab} from "react-bootstrap";
import "./Pagenavbar.scss";
const Pagenavbar = (props) => {
  const links =
    props.navbar === "transactions"
      ? [
          {
            name: "Pending",
          },
          {
            name: "Completed",
          },
          {
            name: "All",
          },
        ]
      : [];

  return (
    <>
      <div className="page-nav-wrap">
        <div className="page-nav-bar justify-content-between bg-lighter">
          <div className="page-nav">
            <ul className="nav">
              {links.map((link, index) => {
                return (
                  <li key={index}>
                    <p className="is-active">
                      <strong>{link.name}</strong>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="search flex-grow-1 pl-lg-4 w-sm-auto">
            <Form>
              <Form.Group className=" input-wrap">
                <span className="input-icon input-icon-left">
                  <FaSearch />
                </span>
                <Form.Control
                  className="input-solid input-transparent"
                  type="text"
                  placeholder="Quick search"
                />
              </Form.Group>
            </Form>
          </div>
          <div className="file-icon">
            <FaFileExport />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagenavbar;
