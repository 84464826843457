import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./Home.scss";
const Home = () => {
  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col
            lg={6}
            className="page-intro-side page-intro-side-a bg_secondary text-white"
          >
            <h2 className="page-intro-title top-margin">
              <span className="text-white">Backend/Owner Panel</span>{" "}
              <small className="text-white fs-14 ucap">Admin Dashboard</small>
            </h2>

            <p className="lead">
              Login as a <strong>Admin/Site Owner</strong>, and take a look at
              your <br />
              <strong> STO Dashboard</strong>.
            </p>
            <Link to="/login" className=" btn-lg btn_secondary text-white">
              Login as Admin
            </Link>

            <div className="hint hint-primary">
              * <strong>Demo Admin Account</strong> is restricted for saving
              actions.
            </div>
            {/* <div className="page-intro-notice text-warning text-center">
                            <p>If you would like to <strong>try private/personal demo</strong>, Please send an email at <a href="mailto:info@softnio.com">info@softnio.com</a></p>
                        </div> */}
          </Col>

          <Col
            lg={6}
            className="page-intro-side page-intro-side-a bg_primary  text-black"
          >
            <h2 className="page-intro-title secondary-title">
              <span>Contributor/Investor Panel</span>{" "}
              <small className="text_dark fs-14 ucap">USER DASHBOARD</small>
            </h2>

            <p className="lead text_dark">
              Register with your <strong>Real Email</strong> address as a <br />
              <strong>Contributor or Investor</strong> and check out User Panel.
            </p>
            <Link to="/register" className=" btn-lg btn_primary text-white">
              Register as Contributor
            </Link>

            <div className="hint hint-secondary">
              * We don't share email address with anyone.
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Home;
