import React from "react";
import {Col, Container, Row, Card, Table} from "react-bootstrap";

import Heading from "../../../components/Heading/Heading";
import Pagenavbar from "../../../components/Pagenavbar/Pagenavbar";
import "./Userlist.scss";
import Usertable from "./Usertable";
const Userlist = () => {
  return (
    <>
      <div className="page-content">
        <Container>
          <Row>
            <Card>
              <Card.Body>
                <Heading heading="Investor User List" />
                <Pagenavbar navbar="users" />
                <Usertable />
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Userlist;
