import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import HeaderWrap from '../../components/HeaderWrap/HeaderWrap';
import './Privacypolicy.scss'
function Privacypolicy() {
    return (
        <>
            <HeaderWrap />
            <div className='page-content'>
                <Container>
                    <Row>
                        <Col className='main-content'>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Privacy and Policy

                                    </Card.Title>
                                    <Card.Text>
                                        ALternative assets  Privacy and Policies content here.



                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />

        </>
    );
}

export default Privacypolicy;
