import React from "react";
import { Col, Form, DropdownButton, Dropdown } from "react-bootstrap";
import logo from "../../assets/images/black-logo.svg";
import Checkbox from "../../components/Checkbox/Checkbox";
import Input from "../../components/Input/Input";
import { Link } from "react-router-dom";
import "boxicons";
import Logform from "../../components/Logform/Logform";
import Custombtn from "../../components/Custombtn/Custombtn";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Signup.scss";
import { signUp } from "../../state/actioncreators/auth.actions";
const Signup = (props) => {
  const dispatch = useDispatch();
  const signUpSchema = Yup.object().shape({
    first_name: Yup.string().required("This Field is required"),
    last_name: Yup.string().required("This Field is required"),
    email: Yup.string().email().required("This Field is required"),
    password: Yup.string()
      .required("This Field is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    check_box: Yup.boolean("Select this checkbox please").oneOf(
      [true],
      "Select this checkbox please"
    ),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      check_box: false,
    },
    validationSchema: signUpSchema,

    onSubmit: (values) => {
      _signUp();
    },
  });

  const _signUp = () => {
    const data = formik.values;
    delete data["check_box"];
    delete data["confirm_password"];
    dispatch(signUp(data)).then((res) => {
      console.log("res=====", res);
      props.history.push({
        pathname: "/login",
      });
      if (res && res.status) {
        // toast.success('Offer created successfully');
      }
    });
  };
  const _changeHandler = (e) => {
    const { name, value, checked } = e.target;
    if (name === "check_box") formik.setFieldValue([name], checked);
    else formik.setFieldValue([name], value);
  };
  console.log("formik.errors", formik.errors);
  return (
    <>
      <Col className="page-ath-wrap">
        <Col md={8} className="login-bg"></Col>
        <Col md={4} className="right-side">
          <div className="page-wth-header">
            <Link to="/">
              {" "}
              <img className="login-logo" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="page-ath-form">
            <Logform
              heading="Sign up "
              text="Create New Alternative assets Account"
            />
            {/* onSubmit={formik.handleSubmit} */}
            <Form className="login-form">
              <Input
                name="first_name"
                formtext="First Name"
                type="text"
                onChange={(e) => {
                  formik.handleChange(e);
                  _changeHandler(e);
                }}
                value={formik.values.first_name}
                isInvalid={
                  formik.touched.first_name && !!formik.errors.first_name
                }
                touched={formik.touched.first_name}
                error={formik.errors.first_name}
              />

              <Input
                name="last_name"
                formtext="Last Name"
                type="text"
                onChange={(e) => {
                  formik.handleChange(e);
                  _changeHandler(e);
                }}
                value={formik.values.last_name}
                isInvalid={
                  formik.touched.last_name && !!formik.errors.last_name
                }
                touched={formik.touched.last_name}
                error={formik.errors.last_name}
              />

              <Input
                name="email"
                type="email"
                formtext="Your Email"
                onChange={(e) => {
                  formik.handleChange(e);
                  _changeHandler(e);
                }}
                value={formik.values.email}
                isInvalid={formik.touched.email && !!formik.errors.email}
                touched={formik.touched.email}
                error={formik.errors.email}
              />

              <Input
                name="password"
                type="password"
                formtext="Password"
                onChange={(e) => {
                  formik.handleChange(e);
                  _changeHandler(e);
                }}
                value={formik.values.password}
                isInvalid={formik.touched.password && !!formik.errors.password}
                touched={formik.touched.password}
                error={formik.errors.password}
              />

              <Input
                name="confirm_password"
                type="password"
                formtext="Repeat Password"
                onChange={(e) => {
                  formik.handleChange(e);
                  _changeHandler(e);
                }}
                value={formik.values.confirm_password}
                isInvalid={
                  formik.touched.confirm_password &&
                  !!formik.errors.confirm_password
                }
                touched={formik.touched.confirm_password}
                error={formik.errors.confirm_password}
              />

              <div className="d-flex check-col">
                <div className="d-flex align-items-start">
                  <Checkbox
                    name="check_box"
                    checked={formik.values.check_box}
                    onChange={(e) => {
                      formik.handleChange(e);
                      _changeHandler(e);
                    }}
                  />
                  <label>
                    I agree to the <Link to="/"> Terms and Condition</Link> and{" "}
                    <Link to="/"> Privacy and Policy.</Link>{" "}
                  </label>
                </div>
              </div>
              <Link to="/login">
                <Custombtn
                  className="w-100"
                  text="Create Account"
                  type="submit"
                  disabled={Object.keys(formik.errors).length !== 0}
                />
              </Link>
            </Form>
            <div className="form-note">
              Already have an account ?{" "}
              <Link to="/login">
                {" "}
                <strong>Sign in instead</strong>
              </Link>
            </div>
          </div>

          <div className="page-ath-footer">
            <ul className="socials mb-3">
              <li>
                <a href="#">
                  <box-icon type="logo" name="facebook"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="twitter" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="linkedin" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="github" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="youtube" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="medium" type="logo"></box-icon>
                </a>
              </li>
              <li>
                <a href="#">
                  <box-icon name="telegram" type="logo"></box-icon>
                </a>
              </li>
            </ul>
            <ul className="footerlinks">
              <li>
                <Link to="/privacy-policy">Privacy and Policy</Link>
              </li>
              <li>
                <Link to="/">Terms and Condition </Link>
              </li>
              <div className="mb-0 lang-switch">
                {["up"].map((direction) => (
                  <DropdownButton
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    variant="secondary"
                    title={` EN  `}
                  >
                    <Dropdown.Item eventKey="1">ENGLISH</Dropdown.Item>
                  </DropdownButton>
                ))}
              </div>
            </ul>
            <div className="copyright-text">
              © 2022 Alternative assets. All Right Reserved.
            </div>
          </div>
        </Col>
      </Col>
    </>
  );
};
export default Signup;
