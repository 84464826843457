import { combineReducers } from "redux";
// import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import user from "./user";
import loader from "./loader";
import remember from "./rememberme"
// import investor from './investor'
// import ethereum from './ethereum'

export const history = createBrowserHistory();
const appReducer = combineReducers({
  user,
  loader,
  remember,
//   investor:investor,
//   ethereum:ethereum,
//   form: formReducer,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USERS_PERSIST") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
