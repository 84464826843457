import React from "react";
import { Col, Container, Row, Card, Badge, ProgressBar } from "react-bootstrap";
import Heading from "../../../components/Heading/Heading";
import "./Icostage.scss";
const Stage = [
  {
    title: "STAGE NAME",
    badge: "Running",
    heading: "Demo Stage",
    progresstext: "97.7%",
    sold: "Sold",
    tokenname: "Token Issued",
    total: "950,000",
    sold: "Sold ",
    soldtokens: "927,694 Tokens",
    price: "Base Price",
    pricetext: "0.25",
    bonus: "Base Bonus",
    bonustext: "20",
    startdate: "Start Date",
    date: "06 Apr, 2019",
    time: "01:41 PM",
    enddate: "End Date",
    enddatetext: "31 Aug, 2019",
  },
];
const Icostage = () => {
  return (
    <>
      <div className="page-content ico-stage-card">
        <Container>
          <Row>
            <Card className="g-4">
              <Card.Body>
                <Heading heading="Available ICO/STO Stage" />

                <Row xs={1} md={2} lg={3} className="g-4">
                  {Stage.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <Col >
                        <Card gap={2} className="stage-card ">
                          <Card.Body className="p-0">
                            <Card.Title className="stage-title">
                              <h6>
                                {" "}
                                {item.title}
                                <Badge bg="success">{item.badge}</Badge>
                              </h6>
                              <h4>{item.heading}</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="stage-info">
                                <div className="progress blue">
                                  {" "}
                                  <span className="progress-left">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>{" "}
                                  <span className="progress-right">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>
                                  <div className="progress-value">
                                    {item.progresstext}
                                    <span className="progress-status">
                                      {" "}
                                      {item.sold}
                                    </span>
                                  </div>
                                </div>
                                <div className="stage-info-txt">
                                  <h6>{item.tokenname}</h6>
                                  <span className="stage-info-total h4">
                                    {item.total}
                                  </span>
                                  <div className="stage-info-count">
                                    {" "}
                                    {item.sold} <span>{item.soldtokens}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.price}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.pricetext}
                                      <small>USD</small>
                                    </div>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.bonus}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.bonustext}
                                      <small>%</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.startdate}</h6>
                                    <h5>{item.date}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.enddate}</h6>
                                    <h5>{item.enddatetext}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                </div>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="stage-card">
                          <Card.Body className="p-0">
                            <Card.Title className="stage-title">
                              <h6>
                                {" "}
                                {item.title}
                                <Badge bg="success">{item.badge}</Badge>
                              </h6>
                              <h4>{item.heading}</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="stage-info">
                                <div className="progress blue">
                                  {" "}
                                  <span className="progress-left">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>{" "}
                                  <span className="progress-right">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>
                                  <div className="progress-value">
                                    {item.progresstext}
                                    <span className="progress-status">
                                      {" "}
                                      {item.sold}
                                    </span>
                                  </div>
                                </div>
                                <div className="stage-info-txt">
                                  <h6>{item.tokenname}</h6>
                                  <span className="stage-info-total h4">
                                    {item.total}
                                  </span>
                                  <div className="stage-info-count">
                                    {" "}
                                    {item.sold} <span>{item.soldtokens}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.price}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.pricetext}
                                      <small>USD</small>
                                    </div>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.bonus}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.bonustext}
                                      <small>%</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.startdate}</h6>
                                    <h5>{item.date}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.enddate}</h6>
                                    <h5>{item.enddatetext}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                </div>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="stage-card">
                          <Card.Body className="p-0">
                            <Card.Title className="stage-title">
                              <h6>
                                {" "}
                                {item.title}
                                <Badge bg="success">{item.badge}</Badge>
                              </h6>
                              <h4>{item.heading}</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="stage-info">
                                <div className="progress blue">
                                  {" "}
                                  <span className="progress-left">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>{" "}
                                  <span className="progress-right">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>
                                  <div className="progress-value">
                                    {item.progresstext}
                                    <span className="progress-status">
                                      {" "}
                                      {item.sold}
                                    </span>
                                  </div>
                                </div>
                                <div className="stage-info-txt">
                                  <h6>{item.tokenname}</h6>
                                  <span className="stage-info-total h4">
                                    {item.total}
                                  </span>
                                  <div className="stage-info-count">
                                    {" "}
                                    {item.sold} <span>{item.soldtokens}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.price}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.pricetext}
                                      <small>USD</small>
                                    </div>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.bonus}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.bonustext}
                                      <small>%</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.startdate}</h6>
                                    <h5>{item.date}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.enddate}</h6>
                                    <h5>{item.enddatetext}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                </div>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="stage-card">
                          <Card.Body className="p-0">
                            <Card.Title className="stage-title">
                              <h6>
                                {" "}
                                {item.title}
                                <Badge bg="success">{item.badge}</Badge>
                              </h6>
                              <h4>{item.heading}</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="stage-info">
                                <div className="progress blue">
                                  {" "}
                                  <span className="progress-left">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>{" "}
                                  <span className="progress-right">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>
                                  <div className="progress-value">
                                    {item.progresstext}
                                    <span className="progress-status">
                                      {" "}
                                      {item.sold}
                                    </span>
                                  </div>
                                </div>
                                <div className="stage-info-txt">
                                  <h6>{item.tokenname}</h6>
                                  <span className="stage-info-total h4">
                                    {item.total}
                                  </span>
                                  <div className="stage-info-count">
                                    {" "}
                                    {item.sold} <span>{item.soldtokens}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.price}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.pricetext}
                                      <small>USD</small>
                                    </div>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.bonus}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.bonustext}
                                      <small>%</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.startdate}</h6>
                                    <h5>{item.date}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.enddate}</h6>
                                    <h5>{item.enddatetext}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                </div>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="stage-card">
                          <Card.Body className="p-0">
                            <Card.Title className="stage-title">
                              <h6>
                                {" "}
                                {item.title}
                                <Badge bg="success">{item.badge}</Badge>
                              </h6>
                              <h4>{item.heading}</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="stage-info">
                                <div className="progress blue">
                                  {" "}
                                  <span className="progress-left">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>{" "}
                                  <span className="progress-right">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>
                                  <div className="progress-value">
                                    {item.progresstext}
                                    <span className="progress-status">
                                      {" "}
                                      {item.sold}
                                    </span>
                                  </div>
                                </div>
                                <div className="stage-info-txt">
                                  <h6>{item.tokenname}</h6>
                                  <span className="stage-info-total h4">
                                    {item.total}
                                  </span>
                                  <div className="stage-info-count">
                                    {" "}
                                    {item.sold} <span>{item.soldtokens}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.price}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.pricetext}
                                      <small>USD</small>
                                    </div>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.bonus}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.bonustext}
                                      <small>%</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.startdate}</h6>
                                    <h5>{item.date}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.enddate}</h6>
                                    <h5>{item.enddatetext}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                </div>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="stage-card">
                          <Card.Body className="p-0">
                            <Card.Title className="stage-title">
                              <h6>
                                {" "}
                                {item.title}
                                <Badge bg="success">{item.badge}</Badge>
                              </h6>
                              <h4>{item.heading}</h4>
                            </Card.Title>
                            <Card.Text>
                              <div className="stage-info">
                                <div className="progress blue">
                                  {" "}
                                  <span className="progress-left">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>{" "}
                                  <span className="progress-right">
                                    {" "}
                                    <span className="progress-bar"></span>{" "}
                                  </span>
                                  <div className="progress-value">
                                    {item.progresstext}
                                    <span className="progress-status">
                                      {" "}
                                      {item.sold}
                                    </span>
                                  </div>
                                </div>
                                <div className="stage-info-txt">
                                  <h6>{item.tokenname}</h6>
                                  <span className="stage-info-total h4">
                                    {item.total}
                                  </span>
                                  <div className="stage-info-count">
                                    {" "}
                                    {item.sold} <span>{item.soldtokens}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.price}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.pricetext}
                                      <small>USD</small>
                                    </div>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.bonus}</h6>
                                    <div className="h2 stage-info-number">
                                      {item.bonustext}
                                      <small>%</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="stage-info">
                                <div className="price-info">
                                  <div className="stage-info-txt">
                                    <h6>{item.startdate}</h6>
                                    <h5>{item.date}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                  <div className="stage-info-txt">
                                    <h6>{item.enddate}</h6>
                                    <h5>{item.enddatetext}</h5>
                                    <small>{item.time}</small>
                                  </div>
                                </div>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Icostage;
