import React from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { Spinner } from "react-bootstrap";

function LoaderComponent() {
  const { isLoading } = useSelector((state) => state.loader);

  if (isLoading) {

    return (
      <div className="overlayloader">
        <div className="loader  loader-box">
          <Spinner animation="border" variant="light" />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }

}

export default LoaderComponent;
