import React from 'react';
import './App.scss';
import Application from './Application';
import './assets/theme/animation.scss';
import {connect} from 'react-redux';
import {simpleAction} from './state/actioncreators/simpleAction';

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction())
})

const App = () => {
  return (
    <Application/>
  );
}

export default connect()(App);
