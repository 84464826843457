import React from 'react';
import Button from 'react-bootstrap/Button'
import './Custombtn.scss'
function Custombtn(props) {
    return (
        <div className='custom-btn'>
            <Button disabled={props.disabled} className={`${props.className}`} variant="primary" type={props.type} onClick={props.onClick}>{props.text}</Button>
        </div>
    )
}

export default Custombtn;
