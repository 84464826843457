import React from 'react'
import {Col, Container, Row, Card, Badge, ProgressBar} from 'react-bootstrap';
import Heading from '../../../components/Heading/Heading';
import Pagenavbar from '../../../components/Pagenavbar/Pagenavbar';
import './Issuerslist.scss'
import Issuerstable from './Issuerstable';

const Issuerslist = () => {
    return (
        <>
            <div className='page-content'>
                <Container>
                    <Row>

                        <Card>
                            <Card.Body>
                                <Heading heading="Issuers List" />
                                <Pagenavbar />
                                <Issuerstable />
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Issuerslist