import axios from "../http-common";
import  {toast}  from "../components/Toast/Toast";
import { setLoader } from "../state/actioncreators/loader";
import { storeInstance } from "../index";
import { RESPONSES } from "../constant";
// import { handleTokenExpiry } from "./UserService";
console.log(`process.env.NODE_ENV`, process.env.NODE_ENV);


// axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (config) => {
    // config.headers["Content-Type"] = "application/json";
    let token = storeInstance.getState()?.user?.token;
    config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    console.log("error", error);
    // storeInstance.dispatch(setLoader(false));
    return error;
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    console.log('response', response)
    // handleTokenExpiry();
    // storeInstance.dispatch(setLoader(false));
    return response;
  },
  (error) => {
    // storeInstance.dispatch(setLoader(fatoastlse));
    // if (process.env.NODE_ENV !== "development") {
    //   handleTokenExpiry(error);
    // }
    handleError(error);
    return error;
  }
);

function formatUrl(url, params) {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : "";
  return `${url}${params}`;
}

function handleError(error) {
  error?.response?.data?.message && toast.error(error.response.data.message);
}

function handleSuccess(res) {
  if (res.status === RESPONSES.SUCCESS)
    res?.data?.message && toast.success(res.data.message);
  else {
    res?.data?.message && toast.info(res.data.message);
  }
}
// formatUrl(url, params), data

export const apiCallPost = (
  url,
  data,
  params = {},
  showToast,
  showLoader
) =>
  new Promise((resolve) => {
    showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .post(formatUrl(url, params), data)
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res.data);
        storeInstance.dispatch(setLoader(false))
      })
      .catch((error) => {
        resolve(null);
      });
  });

export const apiCallGet = (
  url,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    // showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .get(formatUrl(url, params))
      .then((res) => {
        // showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        resolve(null);
      });
  });

export const apiCallPut = (
  url,
  data,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    // showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .put(formatUrl(url, params), data)
      .then((res) => {
        // showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        resolve(null);
      });
  });

export const apiCallPatch = (
  url,
  data,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    // showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .patch(formatUrl(url, params), data)
      .then((res) => {
        // showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        resolve(null);
      });
  });

export const apiCallDelete = (
  url,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    // showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .delete(formatUrl(url, params))
      .then((res) => {
        // showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        resolve(null);
      });
  });
