import { USER } from "../constant/actionTypes";
const initialState = {
    userData: {}
};

export default function user(state = initialState, { type, payload }) {
    switch (type) {
        case USER:
            return { ...state, userData: payload };

        default:
            return state;
    }
}
