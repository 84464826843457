import React from "react";
import {Navbar, Nav, Container, NavDropdown, Button} from "react-bootstrap";
import {Link, NavLink, useLocation} from "react-router-dom";

import "./Subheader.scss";
const Subheader = () => {
  const location = useLocation();
  return (
    <>
      <Navbar expand="lg" className="subheader">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink to="/admin" exact>
                <box-icon name="dashboard" type="solid"></box-icon> Dashboard
              </NavLink>
              <NavLink to="/admin/transactions" exact>
                <box-icon name="transfer"></box-icon> Transactions
              </NavLink>
              <NavLink to="/admin/list" exact>
                <box-icon name="food-menu"></box-icon> Issuers List
              </NavLink>

              <NavLink to="/admin/user" exact>
                <box-icon type="solid" name="user-detail"></box-icon> Users List
              </NavLink>
              <NavLink exact to="/admin/stages">
                <box-icon name="coin-stack" type="solid"></box-icon> ICO/STO
                Stage
              </NavLink>
            </Nav>
            <Button
              className="clear-cache-btn btn-xs"
              variant="outline-success"
            >
              <box-icon name="trash"></box-icon> Clear Cache
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Subheader;
