import React from "react";
import { Col, Container, Row, Card, Table } from "react-bootstrap";
import Footer from "../../../components/Footer/Footer";
import HeaderWrap from "../../../components/HeaderWrap/HeaderWrap";
import Heading from "../../../components/Heading/Heading";
import Pagenavbar from "../../../components/Pagenavbar/Pagenavbar";
import "./Transactions.scss";
import Transactiontable from "./Transactiontable/Transactiontable";

const Transactions = () => {
  return (
    <>
      <div className="page-content">
        <Container>
          <Row>
            <Card>
              <Card.Body>
                <Heading heading="All Transactions" />
                <Pagenavbar navbar="transactions" />
                <Transactiontable />
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Transactions;
