import React from "react";
import {Navbar, Container, Dropdown, NavItem, NavLink} from "react-bootstrap";
import logo from "../../assets/images/logo.svg";
import "./Topbar.scss";
const Topbar = () => {
  return (
    <>
      <Navbar className="main-header" sticky="top" expand="lg">

        <Container>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <Navbar.Brand>
              <img className="logo" src={logo} alt="logo" />
            </Navbar.Brand>
            <Dropdown as={NavItem}>
              <span className="user-welcome d-none d-lg-inline-block">
                Hello! Admin
              </span>{" "}
              <Dropdown.Toggle as={NavLink} className="user-thumb">
                {" "}
                <box-icon type="solid" name="user"></box-icon>
              </Dropdown.Toggle>
              {/* <Dropdown.Menu>
                                <Dropdown.Item>Hello there!</Dropdown.Item>
                            </Dropdown.Menu> */}
            </Dropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Topbar;
