import React from 'react';
import {Col, Container} from 'react-bootstrap';
import './Footer.scss'
const Footer = () => {
    return (
        <>
            <div className="footer-bar">
                <Container>
                    <div className="row align-items-center justify-content-center">
                        <Col md={12}>
                            <div className="copyright-text text-center pb-3">© 2022 Alternative assets. All Rights Reserved. <br className="d-block d-md-none" />Application Developed by <a href="#" target="_blank">Alternative assets</a>.</div>
                        </Col>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Footer;
