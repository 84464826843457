import React from "react";
import { Col, Form, DropdownButton, Dropdown } from "react-bootstrap";
import logo from "../../assets/images/black-logo.svg";
import Checkbox from "../../components/Checkbox/Checkbox";
import Input from "../../components/Input/Input";
import { Link } from "react-router-dom";
import "boxicons";
import Logform from "../../components/Logform/Logform";
import Custombtn from "../../components/Custombtn/Custombtn";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Reset.scss";
import { resetPassword, signUp } from "../../state/actioncreators/auth.actions";
const Reset = (props) => {
    const dispatch = useDispatch();
    const signUpSchema = Yup.object().shape({
        password: Yup.string()
            .required("This Field is required")
            .min(8, "Password must be at least 8 characters"),
        confirm_password: Yup.string()
            .required()
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        check_box: Yup.boolean('Select this checkbox please').oneOf([true], 'Message')
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            confirm_password: "",
            check_box: false
        },
        validationSchema: signUpSchema,

        onSubmit: (values) => {
            _resetPassword();
        },
    });

    const _resetPassword = () => {
        const data = formik.values;
        delete data['check_box'];
        delete data['confirm_password'];
        dispatch(resetPassword(data)).then((res) => {
            console.log('res=====', res)
            props.history.push({
                pathname: '/login',
            });
            if (res && res.status) {
                // toast.success('Offer created successfully');
            }
        });
    };
    const _changeHandler = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'check_box') formik.setFieldValue([name], checked)
        else formik.setFieldValue([name], value)
    };


    return (
        <>
            <Col className="page-ath-wrap">
                <Col md={8} className="login-bg"></Col>
                <Col md={4} className="right-side">
                    <div className="page-wth-header">
                        <Link to="/">
                            {" "}
                            <img className="login-logo" src={logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="page-ath-form">
                        <Logform
                            heading="Reset Password"
                            text="If you reset your password, well, then we'll email you instructions to reset your password."
                        />
                        <Form className="login-form" onSubmit={formik.handleSubmit}>
                            <Input
                                name="password"
                                type="password"
                                formtext="Password"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    _changeHandler(e);
                                }}
                                value={formik.values.password}
                                isInvalid={formik.touched.password && !!formik.errors.password}
                                touched={formik.touched.password}
                            />
                            <span>{formik.errors.password}</span>

                            <Input
                                name="confirm_password"
                                type="password"
                                formtext="Repeat Password"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    _changeHandler(e);
                                }}
                                value={formik.values.confirm_password}
                                isInvalid={
                                    formik.touched.confirm_password &&
                                    !!formik.errors.confirm_password
                                }
                                touched={formik.touched.confirm_password}
                            />
                            <span>{formik.errors.confirm_password}</span>

                            <div className="d-flex check-col">
                                <div className="d-flex align-items-start">
                                    <Checkbox name="check_box"
                                        checked={formik.values.check_box}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            _changeHandler(e);
                                        }}
                                    />
                                    <label>
                                        I agree to the <Link to="/"> Terms and Condition</Link> and{" "}
                                        <Link to="/"> Privacy and Policy.</Link>{" "}
                                    </label>
                                </div>
                            </div>
                            <Custombtn
                                className="w-100"
                                text="Reset Password"
                                type="submit"
                            />
                        </Form>
                        <div className="form-note">
                            Already have an account ?{" "}
                            <Link to="/login">
                                {" "}
                                <strong>Sign in instead</strong>
                            </Link>
                        </div>
                    </div>

                    <div className="page-ath-footer">
                        <ul className="socials mb-3">
                            <li>
                                <a href="#">
                                    <box-icon type="logo" name="facebook"></box-icon>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <box-icon name="twitter" type="logo"></box-icon>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <box-icon name="linkedin" type="logo"></box-icon>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <box-icon name="github" type="logo"></box-icon>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <box-icon name="youtube" type="logo"></box-icon>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <box-icon name="medium" type="logo"></box-icon>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <box-icon name="telegram" type="logo"></box-icon>
                                </a>
                            </li>
                        </ul>
                        <ul className="footerlinks">
                            <li>
                                <Link to="/privacy-policy">Privacy and Policy</Link>
                            </li>
                            <li>
                                <Link to="/">Terms and Condition </Link>
                            </li>
                            <div className="mb-0 lang-switch">
                                {["up"].map((direction) => (
                                    <DropdownButton
                                        key={direction}
                                        id={`dropdown-button-drop-${direction}`}
                                        drop={direction}
                                        variant="secondary"
                                        title={` EN  `}
                                    >
                                        <Dropdown.Item eventKey="1">ENGLISH</Dropdown.Item>
                                    </DropdownButton>
                                ))}
                            </div>
                        </ul>
                        <div className="copyright-text">
                            © 2022 Alternative assets. All Right Reserved.
                        </div>
                    </div>
                </Col>
            </Col>
        </>
    );
};
export default Reset;
